@import './variables.scss';

.Toaster__alert {
	background-color: white;
	overflow: hidden;
	max-width: 650px;
	position: relative;
	border-radius: 0.4rem;
	display: flex;
	padding: 1rem;
	padding-right: 48px;
	box-shadow: var(--shadowColor) 0px 1px 10px 0px,
	var(--shadowColor) 0px 6px 12px 0px,
	var(--shadowColor) 0px 6px 15px -2px;
  }

  .Toaster__alert_text {
	box-sizing: border-box;
	font-family: var(--bodyFontStack);
	color: var(--primary2Color);
	-webkit-font-smoothing: antialiased;
	font-weight: 500;
	line-height: 1.5;
	font-size: 1rem;
	margin: 0px;
  }

  .Toaster__alert_close {
	padding: 12px;
	outline: none;
	cursor: pointer;
	background-color: transparent;
	position: absolute;
	top: 5px;
	right: 4px;
	border: 0;
	-webkit-appearance: none;
	font-size: 20px;
	transition: transform 300ms ease;
  }

  .Toaster__alert_close:hover {
	color: var(--primary1Color);
	transform: rotateZ(90deg);
  }

  .Toaster__message-wrapper {
	padding: 8px;
  }
