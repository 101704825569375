:root {
	--overlineColor: #a2a2a2;

	--iconColor:  #003C3A;
	--headerColor: #003C3A;
	--borderColor:  rgba(0, 60, 58, 0.15);

	--shadowColor: rgba(0, 0, 0, 0.1);

	/* navigation panel */
	--navigationBgColor: var(--background1Color);
	--navigationShadowColor: rgba(0, 0, 0, 0.1);
	--navigationWidth: 80px;

	/* profile pages */
	--profileDetailsBgColor: var(--background1Color);
}
