@mixin customScrollbar {
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin iconBackground($iconName) {
	background-image: url('~@adia/react-ui-building-blocks/icons/#{$iconName}_lined_b.svg');

	&:active,
	&:global(.active) {
		background-image: url('~@adia/react-ui-building-blocks/icons/#{$iconName}_filled_b.svg');
	}
}
