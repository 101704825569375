@import-normalize;
@import './variables.scss';
@import './mixins.scss';
@import './notify.scss';

html {
	font-size: var(16px);
	box-sizing: border-box;
}

* {
	box-sizing: inherit;
}

body {
	margin: 0;
	font-family: var(--bodyFontStack);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--background2Color);
	background: url(../images/workspace.png);
	color: var(--primary2Color);

	@include customScrollbar;

	&.withModal {
		overflow: hidden;
	}
}

a {
	text-decoration: none;
	color: inherit;

	&:hover {
		text-decoration: underline;
	}
}

:focus {
	outline: none;
}

::selection {
	background: var(--primary1Color);
}

input {
	color: var(--primary2Color);
	margin: 0;
	font: var(--bodyFontStack);
	padding: 0;
	border-width: 0;
	resize: horizontal;
}

input::placeholder {
	opacity: 1;
}

button {
	font: var(--bodyFontStack);
}

textarea:focus,
input:focus {
	outline: none;
}
